import { DeviceType, getDeviceType } from 'utils/src/device'

export interface SiteOptions {
    queryString: string
    pagePath: string
    siteLanguage: string
    siteType: DeviceType
}

export const getSiteOptions = (): SiteOptions => {
    return {
        queryString: window.location.search,
        pagePath: window.location.pathname,
        siteLanguage: window.navigator.language,
        siteType: getDeviceType(),
    }
}
