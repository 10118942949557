export type DeviceType = 'mobile' | 'tablet' | 'desktop'

export function getDeviceType(): DeviceType {
    const userAgent: string = navigator.userAgent.toLowerCase()
    const isMobile: boolean =
        /android|iphone|ipod|blackberry|iemobile|windows phone|opera mini|silk|kindle|symbian/.test(userAgent)
    const isTablet: boolean = /ipad|android|windows( nt .*arm| rt )/.test(userAgent)

    if (isMobile) {
        return 'mobile'
    } else if (isTablet) {
        return 'tablet'
    } else {
        return 'desktop'
    }
}
